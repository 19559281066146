import axios from "axios";
import { getLatestValueFromPropertyTransformer } from "./helpers.js";

const API = import.meta.env.VITE_API;

export default {
	deviceId: null,
	equipmentId: null,
	bacnetObjects: null,
	bacnetUnits: null,
	bacnetDataTypes: null,
	lonSnvtTypes: null,

	setDeviceId(deviceId) {
		this.deviceId = deviceId;
	},

	setEquipmentId(equipmentId) {
		this.equipmentId = equipmentId;
	},

	async getDraftRevision(deviceId = null) {
		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/draft/properties`
			)
			.then((res) => res.data)
			.then(convertDisableObjectValuedNullToFalse)
			.catch((err) => {
				throw err.response;
			});
	},

	async getAllFromRevision(
		revision = "current",
		equipmentIdParamName = "equipmentId",
		deviceId = null
	) {
		return this.getAllFromEquipmentAndRevision(
			this.equipmentId,
			revision,
			equipmentIdParamName,
			deviceId
		);
	},

	async getAllFromEquipmentAndRevision(
		equipmentId = null,
		revision = "current",
		equipmentIdParamName = "equipmentId",
		deviceId = null
	) {
		const equipmentParam =
			equipmentId || this.equipmentId
				? `?${equipmentIdParamName}=${equipmentId || this.equipmentId}`
				: "";
		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/${revision}/properties${equipmentParam}`
			)
			.then((res) => res.data)
			.then(convertDisableObjectValuedNullToFalse)
			.catch((err) => {
				throw err.response;
			});
	},

	async create(configs, deviceId = null) {
		return axios
			.post(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/draft/properties`,
				configs
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async update(propertyId, configs, deviceId = null, params = {}) {
		return axios
			.put(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/draft/properties/${propertyId}`,
				configs,
				{ params }
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async delete(propertyId, params = {}, deviceId = null) {
		return axios
			.delete(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/draft/properties/${propertyId}`,
				{
					params
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async bulkInsert(properties = [], deviceId = null) {
		return axios
			.post(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/draft/bulkProperties`,
				{
					insert: properties
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async bulkDelete(propertiesIds = [], deviceId = null, force = true) {
		return axios
			.delete(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/draft/bulkProperties`,
				{
					data: {
						delete: propertiesIds,
						force
					}
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async bulkUpdate(updates = [], deviceId = null, params = {}) {
		return axios
			.put(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/draft/bulkProperties`,
				{
					update: updates
				},
				{
					params
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	// async deleteMultipleProperties(propertiesArray = null, deviceId = null) {
	// 	if (!(propertiesArray instanceof Array))
	// 		throw Error("You must provide an array with properties IDs");

	// 	return axios
	// 		.post(
	// 			`${API}/devices/${deviceId ||
	// 				this.deviceId}/configs/draft/bulkProperties`,
	// 			{
	// 				delete: propertiesArray
	// 			}
	// 		)
	// 		.then(res => res.data)
	// 		.catch(err => {
	// 			throw err.response;
	// 		});
	// },

	async getBacnetObjects() {
		if (this.bacnetObjects !== null) {
			return Promise.resolve(this.bacnetObjects);
		}

		return axios
			.get(`${API}/extras/bacnet/objectProperties`)
			.then((res) => {
				const objects = res.data;
				this.bacnetObjects = objects;
				return objects;
			})
			.catch((err) => {
				throw err.response;
			});
	},

	async getBacnetUnits() {
		if (this.bacnetUnits !== null) {
			return Promise.resolve(this.bacnetUnits);
		}

		return axios
			.get(`${API}/extras/bacnet/engineeringUnits`)
			.then((res) => {
				const units = res.data;
				this.bacnetUnits = units;
				return units;
			})
			.catch((err) => {
				throw err.response;
			});
	},

	async getBacnetDataTypes() {
		if (this.bacnetDataTypes !== null) {
			return Promise.resolve(this.bacnetDataTypes);
		}

		return axios
			.get(`${API}/extras/bacnet/dataTypes`)
			.then((res) => {
				const dataTypes = res.data;
				this.bacnetDataTypes = dataTypes;
				return dataTypes;
			})
			.catch((err) => {
				throw err.response;
			});
	},

	async getLonSnvtTypes() {
		if (this.lonSnvtTypes !== null) {
			return Promise.resolve(this.lonSnvtTypes);
		}

		return axios
			.get(`${API}/extras/lon/snvtTypes`)
			.then((res) => {
				const objects = res.data;
				this.lonSnvtTypes = objects;
				return objects;
			})
			.catch((err) => {
				throw err.response;
			});
	},

	/**
	 * Get latest value from property
	 *
	 * @param {uuid} propertyId
	 * @param {uuid} deviceId
	 */
	async getLatestValueFromProperty({
		propertyId = null,
		deviceId = null,
		params = {}
	}) {
		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/properties/${propertyId}`,
				{
					params: {
						showSpinner: false,
						...params
					},
					// code below is due to int64 data format for some properties.
					// javascript can't parse them as a Number so it's needed a BigInt handler.
					transformResponse: [
						function (data) {
							// when API returns 204 - empty string
							if (!data) {
								return null;
							}

							return getLatestValueFromPropertyTransformer(data);
						}
					]
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async requestLatestValueFromProperty(
		property = null,
		deviceId = null,
		params = {}
	) {
		if (!property) {
			throw Error("You must provide a property Id or property slug");
		}

		return axios
			.put(
				`${API}/devices/${
					deviceId || this.deviceId
				}/properties/${property}`,
				{},
				{
					params: {
						requestRead: true,
						...params
					}
				}
			)
			.then((resp) => resp.data)
			.catch((err) => {
				throw err.response;
			});
	}
};

const convertDisableObjectValuedNullToFalse = (properties) => {
	for (let property of properties) {
		if (property.disabled === null) {
			property.disabled = false;
		}
	}

	return properties;
};
