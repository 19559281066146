<template>
	<div>
		<span
			class="has-text-right has-text-weight-semibold is-size-7 px-4 is-block"
		>
			<slot name="title"></slot>
		</span>
		<!-- Main slot for dropdown items -->
		<slot></slot>
	</div>
</template>

<script setup></script>
