<template>
	<div class="field">
		<ws-form-label
			v-if="(label || $slots.label) && hasAddon"
			:id="`${id}-label`"
			:disabled="disabled"
			:error="!!error"
			:is-inline="isInline"
			:optional="optional"
			:tooltip="tooltip"
			:tooltip-position="tooltipPosition"
		>
			<slot name="label">
				{{ label }}
			</slot>
		</ws-form-label>
		<div
			class="field"
			:class="{
				'is-flex is-justify-content-space-between': isInline,
				'has-addons': hasAddon,
				password: displayShowPasswordButton,
				'has-error': !!error
			}"
		>
			<ws-form-label
				v-if="(label || $slots.label) && !hasAddon"
				:id="`${id}-label`"
				:disabled="disabled"
				:error="!!error"
				:is-inline="isInline"
				:optional="optional"
				:tooltip="tooltip"
				:tooltip-position="tooltipPosition"
			>
				<slot name="label">
					{{ label }}
				</slot>
			</ws-form-label>

			<div class="control" v-if="hasAddon && addonPosition === 'left'">
				<a class="button is-static">
					<slot name="addonText">
						{{ addonText }}
					</slot>
				</a>
			</div>
			<div
				class="control is-expanded inputField"
				:class="{
					'has-icons-right': displayEraseButton || !!error,
					'is-loading': isLoading
				}"
			>
				<input
					v-model="content"
					:aria-labelledby="`${id}-label`"
					:id="id"
					:name="name"
					:data-testid="dataTestid"
					class="input"
					:class="{
						'is-danger': !!error,
						'is-small': size === 'small',
						'is-medium': size === 'medium',
						'is-large': size === 'large',
						uppercase: toUppercase
					}"
					:type="inputType"
					:min="min"
					:max="max"
					:autocomplete="autocomplete"
					:placeholder="placeholder"
					:disabled="disabled || isLoading"
					:required="required"
					:v-ws-focus="hasFocus"
					:step="(type === 'number' && step) || null"
					@input="handleInput"
					@paste="handleOnPaste"
					@blur="$emit('blur')"
					@focus="$emit('focus')"
				/>
				<template v-if="!isLoading">
					<span class="icon is-right has-text-danger" v-if="!!error">
						<ws-icon icon="alert" />
					</span>
					<span
						v-if="displayEraseButton || displayShowPasswordButton"
						class="icon is-right show-addon-buttons"
						:class="{
							password: displayShowPasswordButton
						}"
					>
						<ws-button
							v-if="
								displayEraseButton && !displayShowPasswordButton
							"
							tabindex="-1"
							is-delete
							is-small
							class="delete"
							@click="eraseInput"
							data-testid="erase-input"
						/>

						<button
							v-if="displayShowPasswordButton"
							type="button"
							class="button is-light is-small eye-button"
							:class="{
								'ml-1 mr-1': displayEraseButton
							}"
							tabindex="-1"
							@click="togglePasswordFieldType()"
						>
							<ws-icon
								:icon="showPasswordField ? 'eye-slash' : 'eye'"
							/>
						</button>
					</span>
				</template>
				<p
					class="help mb-0"
					v-if="labelUnderInput || $slots.labelUnderInput"
				>
					<span v-if="labelUnderInput" v-html="labelUnderInput" />
					<slot name="labelUnderInput" />
				</p>
				<p
					v-if="$slots['help-text']"
					class="help"
					:class="{ 'mb-2': !!error }"
				>
					<slot name="help-text"></slot>
				</p>
				<p class="help is-danger" v-if="!!error">{{ error }}</p>
			</div>
			<div class="control" v-if="hasAddon && addonPosition === 'right'">
				<a class="button is-static">
					<slot name="addonText">
						{{ addonText }}
					</slot>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { generateRandomId } from "@/helpers/functions.helper.js";

export default {
	name: "WsFormInput",

	emits: ["update:modelValue", "paste", "blur", "focus"],

	props: {
		id: {
			type: String,
			default: () => {
				return generateRandomId();
			}
		},

		size: {
			type: String,
			default: null,
			validator: function (value) {
				return ["small", "normal", "medium", "large"].includes(value);
			}
		},

		modelValue: {
			type: null,
			default: ""
		},

		dataTestid: {
			type: null,
			default: ""
		},

		label: {
			type: String,
			default: null
		},
		optional: {
			type: Boolean,
			default: false
		},

		placeholder: {
			type: String,
			default: ""
		},

		type: {
			type: String,
			default: "text",
			validator: function (value) {
				const availableHtmlTypes = [
					"email",
					"number",
					"password",
					"text",
					"url"
				];

				return availableHtmlTypes.indexOf(value) !== -1;
			}
		},
		min: {
			type: [String, Number],
			default: null
		},
		max: {
			type: [String, Number],
			default: null
		},
		autocomplete: {
			type: String,
			default: "off"
		},
		name: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		error: {
			type: [String, Boolean],
			default: false
		},
		hasFocus: {
			type: Boolean,
			default: null
		},
		required: {
			type: Boolean,
			default: false
		},
		tooltip: {
			type: [String, Object],
			default: null
		},
		tooltipPosition: {
			type: String,
			default: "top"
		},
		labelUnderInput: {
			type: String,
			default: null
		},
		toUppercase: {
			type: Boolean,
			default: false
		},
		step: {
			type: [String, Number],
			default: 1
		},

		isInline: {
			type: Boolean,
			default: false
		},

		showEraseButton: {
			type: Boolean,
			default: true
		},

		hasAddon: {
			type: Boolean,
			default: false
		},
		addonPosition: {
			type: String,
			default: "left",
			validator: function (value) {
				const availablePositions = ["left", "right"];
				return availablePositions.includes(value);
			}
		},
		addonText: {
			type: String,
			default: null
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			content: this.modelValue,
			showPasswordField: false
		};
	},

	watch: {
		modelValue: function (_newValue) {
			const val = this.toUppercase
				? this.convertToUppercase(_newValue)
				: _newValue;
			this.content = val;
		}
	},

	methods: {
		eraseInput() {
			this.$emit("update:modelValue", null);
		},

		convertToUppercase(val) {
			try {
				return val.toUpperCase();
			} catch {
				return val;
			}
		},

		handleInput(el) {
			const value = el.target.value;

			if (this.type !== "number" && this.toUppercase) {
				return this.$emit(
					"update:modelValue",
					this.convertToUppercase(value)
				);
			}

			return this.$emit("update:modelValue", value);
		},

		handleOnPaste(event) {
			const pastedText = event.clipboardData.getData("text");
			return this.$emit("paste", pastedText);
		},

		togglePasswordFieldType() {
			this.showPasswordField = !this.showPasswordField;
		}
	},

	computed: {
		displayEraseButton() {
			return this.showEraseButton && !this.disabled;
		},

		displayShowPasswordButton() {
			return this.type === "password";
		},

		inputType() {
			if (this.type !== "password") {
				return this.type;
			}

			return this.showPasswordField ? "text" : "password";
		}
	}
};
</script>

<style lang="scss" scoped>
.control.is-loading {
	&::after {
		inset-inline-end: 0.75em;
		position: absolute !important;
		top: 0.75em;
		z-index: 4;
		animation: spinAround 0.5s linear infinite;
		border-bottom: 2px solid $color-grey-400;
		border-left: 2px solid $color-grey-400;
		border-radius: 8px;
		border-right: 2px solid transparent;
		border-top: 2px solid transparent;
		content: "";
		display: block;
		height: 1em;
		width: 1em;
	}
}
.uppercase {
	text-transform: uppercase;
}

.field .inputField {
	.show-addon-buttons {
		display: none;
		&.password {
			display: flex;
		}
	}

	&.password,
	&:hover,
	&:focus {
		> .show-addon-buttons {
			display: flex;
		}
	}
}

.inputField {
	width: 100%;
}

button.eye-button {
	cursor: pointer;
	pointer-events: auto;
	padding: 0;
	border: none;
	background-color: transparent;
	color: rgba(10, 10, 10, 0.3) !important;

	&:hover,
	&:active {
		background-color: transparent;
	}

	&::before,
	&::after {
		content: unset;
		transform: unset;
	}
}

.has-error {
	.control.has-icons-right {
		.icon.show-addon-buttons {
			right: 30px;
		}
	}
	&:hover {
		.control.has-icons-right {
			.input {
				padding-right: 4em;
			}
		}
	}
}
</style>
